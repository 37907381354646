import { render, staticRenderFns } from "./Gangweifenlei.vue?vue&type=template&id=ea77b64c&scoped=true&"
import script from "./Gangweifenlei.vue?vue&type=script&lang=js&"
export * from "./Gangweifenlei.vue?vue&type=script&lang=js&"
import style0 from "./Gangweifenlei.vue?vue&type=style&index=0&id=ea77b64c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea77b64c",
  null
  
)

export default component.exports